<template>
<v-app>
    <div>
        <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
            {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="mb-8 mt-8 text-center">
                <h3>Medical Screening</h3>
                <hr/>
            </div>
            <v-form ref="medicalRecord" v-model="valid">
                <v-row no-gutters>
                    <v-col class="mb-6">
                      <v-btn class="btn-style justify-start text-style" @click.prevent="perSearch = true"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{formData.personnelName == "" ? 'Personnel Name' : formData.personnelName}}</v-btn>
                      <!-- <v-btn class="btn-style justify-start text-style" @click.prevent="openPersonnelSearchModal" width="100%" height="38" outlined>
                        <v-icon left>mdi-magnify</v-icon> Select Personnel
                      </v-btn> -->
                    </v-col>
                    <v-col>
                      <TextField :disable="'true'" v-model="formData.employeeName" :label="'Personnel Name'" />
                    </v-col>
                    <v-col>
                      <SelectField :items="categories" v-model="formData.screeningCategoryId" :label="'Screening Type'" :itemText="'name'" :itemValue="'id'" :returnObject="false" />
                    </v-col>
                    <!--<v-col>
                      <SelectField :items="subCategories" v-model="formData.screeningSubCategoryId" :label="'Sub Category'" :itemText="'name'" :itemValue="'id'" :returnObject="false" />
                    </v-col>-->
                    <v-col>
                      <TextField placeholder="Comment" v-model="formData.comment" />
                    </v-col>
                    <v-col>
                      <TextField type="date" placeholder="Date" v-model="formData.checkupDate" />
                    </v-col>
                </v-row>
            </v-form>
            <!-- <p>{{personnel}}</p> -->
        </div>
    </div>

    <!-- Dialog box -->
    <Dialog ref="personnelSearch" :title="'Personnel Search'" :width="600">
        <v-row>
            <v-col cols="12">
                <v-text-field v-model="search" outlined label="Search" @blur="personnelSearchFunction" dense />
            </v-col>
            <v-col cols="12">
                <v-data-table dense single-select show-select v-model="selectedPersonnel" item-key="id" class="elevation-1 row-pointer" :items-per-page="10" :headers="searchHeaders" :items="personnelSearch"></v-data-table>
            </v-col>
        </v-row>
        <template v-slot:footer>
            <Button :label="'Close'" :btnType="'Cancel'" @onClick="closePersonnelSearchModal" />
        </template>
    </Dialog>
    <!--End Dialog box -->
    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="saving" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
    <PersonnelSearch :dialog="perSearch" @selectPer="selectPersonnel"  @close="perSearch = false" />
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
          <v-row>
            <v-col cols="3" class="d-flex justify-start">
              <Button :label="'Back'" :btnType="'Submit'" @onClick="onBack" />
            </v-col>
            <v-col cols="9" class="d-flex justify-end">
              <Button :label="'Submit'" :btnType="'Submit'" @onClick="dialog = true" :disabled="!valid" :isLoading="saving" class="mr-4" />
              <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
            </v-col>
          </v-row>
        </div>
    </div>
</v-app>
</template>

<script>
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
// import { SAVE_TIMESHEET } from '@/store/action-type'
import { employeeService, medicalScreeningService, medicalScreeningCategoryService } from '@/services'
// import moment from 'moment'

export default {
  components: {
    TextField,
    Dialog,
    Button,
    SelectField,
    PersonnelSearch,
    ConfirmationDialog
  },
  props: ['value'],
  data () {
    return {
      perSearch: false,
      selectedPersonnel: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      saving: false,
      dialog: false,
      formData: {
        employeeId: 0,
        employeeName: '',
        comment: '',
        checkupDate: '',
        screeningCategoryId: ''
      },
      search: '',
      categories: [],
      subCategories: [],
      personnelSearch: [],
      personnel: [],
      selected: [],
      headers: [],
      medicalRecords: [],
      searching: false,
      creating: false,
      searchHeaders: [
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'department',
          value: 'departmentName'
        },
        {
          text: 'rotation',
          value: 'rotationName'
        }
      ],
      personnelHeaders: [
        {
          glutenfree: true,
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.saving = true

      medicalScreeningService.saveMedicalScreenings(this.formData).then(result => {
        this.saving = false
        this.showAlertMessage('Medical screening result successfully saved', 'success')
        this.$refs.medicalRecord.reset()
      }).catch(() => {
        this.showAlertMessage('Unable to save medical records please try again later', 'error')
        this.saving = false
      }).finally(() => {
        this.dialog = false
      })
    },
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    removePersonnel (item) {
      // let index = null
      const newTimesheet = this.medicalRecords.filter(timeSheet => timeSheet.employeeId !== item.employeeId)
      const newPersonnel = this.personnelSearch.filter(person => person.id !== item.employeeId)
      this.medicalRecords = newTimesheet
      this.personnel = newPersonnel

      // for (let i = 0; i < this.medicalRecords.length; i++) {
      //   if (this.medicalRecords[i].emloyeeId === item.emloyeeId) {
      //     index = i
      //     break
      //   }
      // }
      // this.medicalRecords.splice(index, 1)
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
      if (this.selectedPersonnel.length > 0) {
        const employee = this.selectedPersonnel[0]
        this.formData.employeeId = employee.id
        this.formData.employeeName = employee.name
      }
    },
    onBack () {
      this.$router.push('/medical/list')
    },
    onReset () {
      this.$refs.medicalRecord.reset()
    },
    personnelSearchFunction () {
      this.searching = true
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
        this.searching = false
      })
    },
    selectPersonnel (item) {
      this.formData.employeeId = item.id
      this.formData.employeeName = item.name
    }
  },
  mounted () {
    medicalScreeningCategoryService.getMedicalScreeningCategory().then(result => {
      this.categories = result.data
    })
  }
}
</script>

<style scoped>
  .holiday {
    background-color: orange;
    color: #fff;
  }

  .offday {
    background-color: maroon;
    color: #fff;
  }

  .radiogroup {
      width: 50%;
      align-items: flex-start !important;
  }

  .row .col {
      box-shadow: none !important;
      padding: 0 !important;
  }

  .alert {
      position: fixed;
      z-index: 9999;
      top: 5px;
      min-width: 50%;
  }

  .alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
  }

  .alert-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
  }

  .btn-style {
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
  }

  .text-style {
      text-transform: capitalize;
  }

  .actionBtn {
      display: flex;
      justify-content: flex-end;
  }

  .hour-input {
      border: 1px rgb(156, 154, 154) solid;
      width: 50px;
      padding: 1px;
  }

  .v-data-table__wrapper {
      overflow-y: auto !important;
      max-height: 400px !important;
  }
</style>
